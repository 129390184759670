#bt_notification {
  .bt-cookie-section {
    .bt-buttons {
      .btn-wrap {
        &.accept-privacy {
          a {
            &.btn {
              padding: 8px 0;
            }
          }
        }
        &.personalize-privacy {
          a {
            padding: 13px 0;
          }
        }
      }
    }
  }
}
