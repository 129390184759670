.checkout {
  #order-summary-panel {
    .order-summary__total-label,
    .order-summary__total-value {
      font-size: 16px;
    }
  }
}

@media only screen and (device-width: 768px), only screen and (max-width: 768px) {
  .checkout__content {
    .phone-1.form-item,
    .form-item.postal-code,
    .form-item.city {
      position: inherit !important;
    }
  }
}
