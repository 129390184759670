.enhanced-delivery-page {
  .checkout {
    .column.left {
      #shipping-panel {
        .field-container--grid {
          .form-item {
            @include breakpoint($bp--small-down) {
              position: relative !important;
            }
          }
        }
        .billing-address-form-fields {
          .field-container {
            .postal-code {
              .field {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }
}
