.product-foundation-vto {
  &__btn {
    width: 265px;
  }
  &__mobile-ctrl {
    .container {
      .product-size {
        @include swap_direction(margin, 1px 0 0 6px);
      }
    }
  }
}
