.profile-info__fieldset {
  .form_birthday {
    label {
      display: block;
    }
  }
}

.site-container {
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        padding: 1.5em 0.5em;
        .product-header {
          .quantity {
            width: 19.66667%;
          }
          .rating {
            width: 20.11111%;
            margin-left: 46% !important;
          }
          .price {
            margin-right: 14% !important;
            width: 20.11111% !important;
          }
        }
        .product--teaser {
          .product__qty {
            margin-left: 30%;
          }
          .product__rate {
            margin-left: 46%;
            width: 20.11111%;
          }
          .product__price {
            margin-left: 65%;
            width: 20.11111%;
          }
          .product__add {
            width: 19%;
            @include breakpoint($bp--xsmall-down) {
              .product__add-to-bag {
                padding: 2px 0;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

.email-signup-new-user {
  .picker-checkbox {
    .picker-label {
      width: 85%;
    }
  }
}

@if $password_strengthen {
  .profile-password-update {
    &__rules {
      li {
        width: 50%;
      }
    }
  }
}
