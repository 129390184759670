.site-container {
  .search-filter__container {
    .search-dimension__header.results-header {
      @include swap_direction(margin, 0 0 0.25em);
      border: medium none;
    }
  }
  #zero-results {
    .tout-block-landscape {
      img {
        min-height: 247px;
      }
    }
  }
  .store_landing_only {
    .tout-block-landscape__header-image {
      background-image: url('/media/export/cms/locator/makeup-services_cz.png');
    }
  }
  .store-locator {
    .sub-options {
      display: none;
    }
  }
}

.store-locator {
  .form--search--advanced {
    .options {
      .form-item-wrapper {
        @include breakpoint($bp--medium-down) {
          width: 100% !important;
        }
      }
    }
  }
}

.product--teaser {
  @include breakpoint($bp--small-up) {
    min-height: 510px;
  }
}

.page--spp__product {
  .product--not-shoppable {
    .product__footer {
      .product__inventory-status {
        .coming-soon_text,
        .temp-out-of-stock__text {
          bottom: auto;
        }
      }
    }
  }
  .product--full {
    .product__rating {
      text-align: right;
      .product__rating--no_rating {
        top: 0px;
      }
    }
  }
}

.past-purchases {
  .grid--mpp {
    .product--teaser {
      .product__footer {
        .notify-status {
          float: right;
          a.notify-me {
            margin: 0;
            position: static;
          }
        }
        p.product-item__out-of-stock {
          position: static;
          margin-bottom: 1.5em;
          @include breakpoint($bp--large-up) {
            position: absolute;
          }
        }
      }
    }
  }
}

.site-footer {
  .footer-lang--mobile {
    display: none;
  }
  .product--teaser.product--teaser--tiny & {
    width: 100%;
  }
}

.confirm.viewcart {
  .checkout-confirmation-page {
    .responsive-container {
      padding: 0 10px;
    }
  }
}

.site-header {
  li.site-navigation__more {
    &.site-navigation__more {
      ul {
        width: 200%;
      }
    }
  }
}

.media-block--has-caption {
  .media-block__caption {
    .media-block__headline {
      font-size: 1.2rem;
    }
  }
}

// Hide the shipping tout on the mobile SPPs
// The original implementation only enabled them in the PC css
.product__shipping {
  display: none;
}

.tiny-waitlist-overlay {
  .field {
    .email_input {
      input {
        width: 100%;
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .info-link {
      clear: both;
    }
  }
}
