.grid--mpp {
  &__item {
    .product-brief-v2 {
      .product-inventory-status {
        &__temp-oos-text,
        &__coming-soon-text {
          margin-top: 0;
          .notify-status {
            width: 50%;
            position: relative;
            .notify_me {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
